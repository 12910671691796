<template>
  <div class="orders-options slide-in-right">
      <div class="orders-options-header">
          <h2>חלון אפשרויות</h2>
          <div @click="handle_close" class="exit-btn">
              X
          </div>
      </div>
      <div class="orders-options-content">
          <button @click="handle_export_to_excel" class="btn btn-success">ייצוא מסומנים לאקסל</button>
          <button @click="handle_modify_records('טופל')" v-if="status=='חדש'" class="btn btn-danger">העברת מסומנים לטופל</button>
          <button @click="handle_modify_records('חדש')" v-if="status=='טופל'" class="btn btn-warning">העברת מסומנים לחדש</button>
          <button @click="handle_delete_records" class="btn btn-delete">מחיקת מסומנים</button>
      </div>
  </div>
</template>

<script>
import {alert_confirm} from '../../../../../../Methods/Msgs'
export default {
    emits:['close','modify_records','export_to_ecxel'],
    props:['status'],
    setup(_,{emit}){
        
        const handle_export_to_excel = ()=>{
            emit('export_to_ecxel','marks')
        }

        const handle_modify_records = (status)=>{
            alert_confirm(`להעביר מסומנים ל${status}?`)
            .then((res)=>{
                if(res.isConfirmed){
                    emit('modify_records',status)
                }
            })
        }
        const handle_delete_records = ()=>{
            alert_confirm('למחוק רשומות מסומנות?')
            .then((res)=>{
                if(res.isConfirmed){
                    emit('delete_records')
                }
            })
        }
        const handle_close = ()=>{
            emit('close')
        }
        return{
            handle_close,
            handle_modify_records,
            handle_delete_records,
            handle_export_to_excel
            }
    }
}
</script>

<style scoped>
    .orders-options{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 300px;
        height: 400px;
        background: chocolate;
        border-radius: 5px;
        z-index: 9;
    }
    .orders-options-header{
        position: relative;
        width: 100%;
        height: 50px;
        background: var(--success);
        border-radius: 5px  5px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
    }
    .exit-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        transform: translateY(-50%);
        left: 5px;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        z-index: 10;
        cursor: pointer;
        background: var(--danger);
    }
    .orders-options-content{
        width: 100%;
        height: calc(100% - 50px);
        background: whitesmoke;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .btn{
        width: 80%;
        height: 100px;
        border: 0;
        border-radius: 5px;
        font-size: 25px;
        cursor: pointer;
        margin-bottom: 5px;
    }
    .btn-success{
        background: var(--success);
        color: #fff;
    }
    .btn-danger{
        background: var(--danger);
        color: #fff;
    }
    .btn-warning{
        background: var(--warning);
    }
    .btn-delete{
        background: red;
        color: #fff;
    }

    .slide-in-right {
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }


    @-webkit-keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
                transform: translateX(1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
    }
    }
    @keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
                transform: translateX(1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
    }
    }

</style>
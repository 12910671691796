<template>
  <div class="pastramas-main">
      <div v-if="state==null" class="pastramas-main-options">
        <div class="pastramas-main-options-header">
            <h2>מעושנים א.מרסל</h2>
        </div>
        <div class="pastramas-main-options-content">
            <button @click="state='NewOrder'" class="option-btn">
                הזמנה חדשה
            </button>
            <button v-if="role>=99" @click="state='ManageMain'" class="option-btn manage-btn">
                ניהול
            </button>
        </div>
      </div>
      <div v-if="state" class="pastramas-main-states">
          <component @close="state=null" :is="state" />
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import ManageMain from './components/Manage/ManageMain.vue'
import NewOrder from './components/Orders/NewOrder.vue'
import {handle_back_button_on_browser} from '../../../Methods/Back_button_browser'
import { computed } from '@vue/runtime-core'
import store from '../../../store'
export default {
    components:{ManageMain,NewOrder},
    setup(){
        const {state} = handle_back_button_on_browser()
        
        const role = ref(computed(()=>{
            return store.state.role
        }))


        
        return{role,state}
    }
}
</script>

<style>
    .pastramas-main{
        width: 100%;
        height: 100%;
    }
    .pastramas-main-options{
        margin: 0 auto;
        width: 100%;
        max-width: 500px;
        height: 100%;
        color: #fff;
    }
    .pastramas-main-options-header{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .pastramas-main-options-content{
        width: 100%;
        height: calc(100% - 50px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .option-btn{
        width: 250px;
        height: 100px;
        background: var(--success);
        border: 0;
        color: #fff;
        font-size: 25px;
        border-radius: 5px;
        cursor: pointer;
        margin-bottom: 5px;
    }
    .manage-btn{
        background: var(--warning);
    }
    .pastramas-main-states{
        width: 100%;
        height: 100%;
    }
</style>
<template>
  <div class="add-item">
      <div class="add-item-dialog">
          <div class="add-item-dialog-header">
              <h2 v-if="!edit">הוספת פריט חדש</h2>
              <h2 v-if="edit">עריכת פריט קיים</h2>
              <div @click="handle_exit" class="exit-btn">
                  X
              </div>
          </div>
          <div class="add-item-dialog-content">
              <div class="add-item-dialog-content-field">
                <p style="font-size:20px;">שם הפריט</p>
                <el-input v-model="values.item_name" style="width:80%;" placeholder="שם הפריט" />
              </div>
              <div class="add-item-dialog-content-field">
                <p style="font-size:20px;">יחידת מידה</p>
                <el-select style="width:80%" v-model="values.type" clearable placeholder="בחרי יחידת מידה">
                    <el-option
                    v-for="unit in units_options"
                    :key="unit"
                    :value="unit"
                    >
                    </el-option>
                </el-select>
              </div>
          </div>
          <div class="add-item-dialog-footer">
              <el-button v-if="!edit" @click="handle_submit" style="width:80%;" type="success">הוספת פריט</el-button>
              <el-button v-if="edit" @click="handle_submit" style="width:80%;" type="success">עדכן פריט</el-button>
          </div>
      </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {slide_pop_error} from '../../../../../Methods/Msgs'
export default {
    emits:['close','addNewItem','updateItem'],
    props:['edit'],
    setup(props,{emit}){
        const errr_msg = ref('')
        const units_options = ref([
            'יח\'',
            'ק"ג'
        ])
        
        const values = ref({
            item_name:'',
            type:''
        })

        const validation = ()=>{
            for (const [key, value] of Object.entries(values.value)) {
                switch (key) {
                    case 'item_name':
                        if(!value){
                            errr_msg.value = 'עליך להזין שם מוצר'
                            return false
                        }
                        break;
                    case 'type':
                        if(!value){
                            errr_msg.value = 'עליך לבחור יחידת מידה'
                            return false
                        }
                        break;
                }
            }
            return true
        }
        const handle_submit = ()=>{
            if(!validation()){
                slide_pop_error(errr_msg.value)
            }else{
                if(!props.edit)emit('addNewItem',values.value)
                if(props.edit)emit('updateItem',values.value)
            }
        }
        const handle_exit = ()=>{
            emit('close')
        }

        if(props.edit){
            values.value = props.edit
            console.log(values.value);      
        }

        return{
            units_options,
            handle_exit,
            handle_submit,
            validation,
            values,
            errr_msg
        }
    }
}
</script>

<style scoped>
    .add-item{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
        z-index: 8;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .add-item-dialog{
        width: 100%;
        max-width: 500px;
        height: 250px;
        background: #fff;
    }
    
    .add-item-dialog-header,.add-item-dialog-footer{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .add-item-dialog-content{
        padding: 0 5px;
        width: 100%;
        height: calc(100% - 100px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .add-item-dialog-content-field{
        width: 100%;
        height: fit-content;
        margin-bottom: 5px;
    }
    .exit-btn{
        position: absolute;
        top: 5px;
        left: 5px;
        background: var(--danger);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        z-index: 9;
        cursor: pointer;
        color: #fff;
    }
    
</style>
<template>
  <div class="item">
      <div class="item-name">
        <h3>{{item.item_name}} - ({{item.type}})</h3>
      </div>
      <div class="actions">
          <img @click="handle_delete(item)" src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Ftrash-bin_375x375.png?alt=media&token=343ab5d5-380b-4c00-9c6d-ca5152b4673b" alt="">
          <img @click="update_item(item)" src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Fpencil%20(1)_375x375.png?alt=media&token=78a64ec1-c7b4-49d0-ba40-963c4db41bed" alt="">
      </div> 

  </div>
</template>

<script>
import {alert_confirm} from '../../../../../Methods/Msgs'
import {projectFirestore} from '../../../../../firebase/config'
export default {
    props:['item'],
    emits:['delete','modify'],
    setup(_,{emit}){
        const update_item=async(item)=>{
           emit('modify',item)
        }
        
        const handle_delete = async(item)=>{
            alert_confirm(`האם למחוק את המוצר ${item.item_name}`)
            .then(async(res)=>{
                if(res.isConfirmed){
                    await projectFirestore.collection('Applications').doc('ukDiSJTDxi8Bg2D71eQQ').collection('Items')
                    .doc(item.uid).delete()
                    emit('delete',item.uid)
                }
            })

        }
      
        return{handle_delete,update_item}
    }
}
</script>

<style scoped>
    .item{
        background: #fff;
        width: 100%;
        height: 100%;
        -webkit-box-shadow: 5px 5px 10px 5px rgba(0,0,0,0.15); 
        box-shadow: 5px 5px 10px 5px rgba(0,0,0,0.15);
        border-radius: 10px;
        display: flex;
    }
    .item-name{
        width: 60%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .actions{
        width: 40%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    img{
        cursor: pointer;
        width: 32px;
        height: 32px;
    }
</style>